import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from 'gatsby';
import Slider from 'react-slick';
import GetNegotiator from "@components/property-contact/get-property-team";
import ImageRenderer from "@components/ImageRenderer/ImageRenderer";
import {  Bedroom, Bathroom, Reception} from '@components/icon/icon';

const PropertyList = ({ properties, searchType }) => {
    if (!properties || properties.length === 0) {
        return null;
    }
    const textTruncate = (str, length = 200, ending = '...') => {
        if (str.length > length) {
            return str.substring(0, length - ending.length) + ending;
        } else {
            return str;
        }
    };

    const htmlDecode = (html) => {
        return html.replace(/<(\/?)(\w+)[^>]*\/?>/g, (_, endMark, tag) => {
            return tag ? `<${endMark}${tag}>` : ' ';
        }).replace(/<!--.*?-->/g, ' ');
    };

    const uriStr = searchType === 'sales' ? 'property-for-sale/' : 'property-to-rent/';
    const settings = {
        dots: false,
        arrows: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <Container className="property-list-container">
            <Row>
                {properties.map((property, i) => {
                    const propertyDesc = htmlDecode(property.description?.data?.description || property.description);
                    return (
                        <Col key={property.id} className="property-box">
                            <div className="property-box-row child-list" onClick={() => { sessionStorage.setItem('searchindex', `property${property.id}`) }}>
                                <Row>
                                    <Col md="12" lg="5">
                                        <Link to={`/${uriStr}${property.slug}-${property.id}/`}>
                                            <div className="property-slider">
                                                {(property.status === "Sold" || property.status === "Under Offer") &&
                                                    <div className="flagimg text-18-14">{property.status}</div>
                                                }
                                                <Slider {...settings}>
                                                    {property.images?.map((image, index) => (
                                                        index <= 4 && (
                                                            <div key={index}>
                                                                <ImageRenderer ImageSrc={{url:image.url}} altText={`${property.display_address} - Anthony Pepe`} ggfx_results={property?.ggfx_results} strapi_id={property.id}  imagename="property.images.noResultImages" />
                                                            </div>
                                                        )
                                                    ))}
                                                </Slider>
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col md="12" lg="7">
                                        <div className="property-content d-flex flex-wrap">
                                            <div className="top">
                                                <h5>
                                                    <Link to={`/${uriStr}${property.slug}-${property.id}/`} >
                                                        {htmlDecode(property.display_address)}
                                                    </Link>
                                                </h5>
                                                {property.price && property.price > 0 && (
                                                    <div className="price text-24 bold">£{property.price.toLocaleString()}</div>
                                                )}
                                                <div className="bedrooms text-20-16">{property.title}</div>
                                                <div className="room-list">
                                                    {property.bedrooms && property.bedrooms > 0 && (
                                                        <div className="room-icons">
                                                            <Bedroom />
                                                            <span>{property.bedrooms} {property.bedrooms > 1 ? 'Bedrooms' : 'Bedroom'}</span>
                                                        </div>
                                                    )}
                                                    {property.bathroom && property.bathroom > 0 && (
                                                        <div className="room-icons">
                                                            <Bathroom />
                                                            <span>{property.bathroom} {property.bathroom > 1 ? 'Bathrooms' : 'Bathroom'}</span>
                                                        </div>
                                                    )}
                                                    {property.reception && property.reception > 0 && (
                                                        <div className="room-icons">
                                                            <Reception />
                                                            <span>{property.reception} {property.reception > 1 ? 'Receptions' : 'Reception'}</span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="content d-md-none d-lg-block d-none">
                                                    <p>{propertyDesc && textTruncate(propertyDesc)}</p>
                                                </div>
                                            </div>
                                            {property?.negotiator &&
                                                <div className="bottom mt-auto getNegotiator">
                                                    <div className="contact-box-list">
                                                        <GetNegotiator negotiator={property?.negotiator || ''} />
                                                    </div>
                                                    <div className="view-details">
                                                        <Link to={`/${uriStr}${property.slug}-${property.id}/`} >
                                                            View Details
                                                        </Link>
                                                    </div>
                                                </div>
                                            }
                                            
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    );
                })}
            </Row>
        </Container>
    );
};

export default PropertyList;